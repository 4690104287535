<template>
  <div class="page">
    <!--pages/product-detail/product-detail.wxml-->
    <div class="banner-box">
	  <div class="goodsSwiper">
	  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#FFD11A">
	    <van-swipe-item  v-for="item in goodsInfo.images" :key="item.img_id">
	      <div class="swiper-item">
	        <img class="ban" :src="item.image_url">
	      </div>
	    </van-swipe-item>
	  </van-swipe>
	  </div>
      <div class="desc-box">
        <div class="price-box flexcenter">
          <div class="flexcenter">
            <div class="price">￥{{goodsInfo.front_price}}</div>
          </div>
          <div class="yx">月销{{goodsInfo.sale_num}}</div>
        </div>
      
        <div class="title-box flexcenter">
          <div class="title1">{{goodsInfo.goods_name}}</div>
          <!-- <div class="share flexcenter" @click="goShare">
            <i class="iconfont iconfenxiang"></i>
            <span>分享</span>
          </div> -->
        </div>
      </div>
  
    </div>
    
	<div class="gap"></div>
	
	<div class="otherInfo">
	  <div class="title">规格信息</div>
	  <van-cell-group>
	    <van-cell title="品牌" :value="goodsInfo.brand?goodsInfo.brand.name:''" />
	    <van-cell title="类别" :value="goodsInfo.group_name+'\''+goodsInfo.category_name" />
	    <van-cell title="编码" :value="goodsInfo.goods_sn" />
	    <van-cell title="产地" :value="goodsInfo.diy_attr_thir" />
	    <van-cell title="库存" :value="goodsInfo.can_sale_stock+'件'" :border="false"/>
	  </van-cell-group>
	</div>
	
    <div class="gap"></div>
  
    <div class="details">
      <div class="title">商品详情</div>
	  <div class="content" v-html="goodsInfo.mob_content"></div>
      <!-- <img class="full-img" mode="widthFix" src="~images/daoyou2.png"> -->
    </div>
  
    <div class="bottom flexcenter">
      <!-- <div class="item flexcenter">
        <i class="iconfont iconkefu"></i>
        <span>客服</span>
      </div>
      <div class="item flexcenter">
        <i class="iconfont iconshoucang"></i>
        <span>收藏</span>
      </div> -->
      <div class="item flexcenter" @click="goCartPage">
		<span class="badge" v-if="cartTotal>0">{{cartTotal}}</span>
        <span class="iconfont icongouwuche"></span>
        <span>购物车</span>
      </div>
      <div class="action-box flexcenter">
        <div @click="addToCartLocal" class="left">加入购物车</div>
        <div @click="quickToBuy" class="right">立即购买</div>
      </div>
    </div>
  
    <van-cell title="显示分享面板" @click="showShare = true" />
    <van-share-sheet
        v-model="showShare"
        title="立即分享给好友"
        :options="shareOptions"
        @select="selectShare"
    />
	<product-sku-popup 
		:skuPopShow="showSkuPop" 
		skuPopTitle="选择商品规格" 
		:skuPopGoods="goodsInfo" 
		@updateSelectedSku="updateSelectedSku" 
		@addToCartLocal="addToCartLocal" 
		@closeSkuPop="closeSkuPop"
		@quickToBuy="quickToBuy">
	</product-sku-popup>
  </div>
</template>

<script>
  import productSkuPopup from 'components/product-sku-popup'

  export default {
    name: 'product-detail',
    data() {
      return {
        goodsInfo:{},
        cartTotal:'0',
        selectedSkuId:'',
        selectedSkuName:'请选择',
        selectedSkuNumber:'1',
        swiperImgWidth:'0',
        showShare:false,
        showSkuPop:false,
        sharePacket:{
          isState:true,
          priceName:0
        },
        shareOptions: [
          { name: '微信', icon: 'wechat', openType: 'share' },
          { name: '朋友圈', icon: 'https://img01.yzcdn.cn/vant/share-sheet-wechat-moments.png', openType: 'share' }
        ],
      }
    },
	components: {
	  productSkuPopup,
	},
    created() {
      let gid = this.$route.query.gid;
      if(typeof gid=='undefined'||gid<1) {
        // wx.showToast({
        //   title:"商品不存在",
        //   icon:"none"
        // });

        setTimeout(() => {
          // this.$router.back()
        }, 1000);
      }

      this.getGoodsInfo(gid);
      this.refeshCartTotal();
    },
    methods: {
      /**刷新购物车数量 */
      refeshCartTotal() {
        let user_id = localStorage.getItem('user_id');
        if(user_id>0) {
          let that = this;
          this.post(
            this.API.refeshCartTotal, {}
          ).then(response => {
            that.cartTotal = response.data
          })
        }
      },
      /**获取商品信息 */
      getGoodsInfo(gid){
        let that = this;
        this.post(
          this.API.addCart,{gid:gid}
        ).then(response => {
          this.goodsInfo = response.data.goods
          this.sharePacket = {
            isState:!response.data.is_promoter,
            priceName:response.data.spread_price
          }
          //html转wxml
          window.WxParse && WxParse.wxParse('description', 'html', response.data.goods.mob_content, that, 0);
        })
      },
      /**
       * SKU选择器
       */
      goSkuPop(){
        this.showSkuPop = true
      },
      /**
       * 加入购物车
       */
      addToCartLocal(e){
		
		let selectedSkuNumber = 1;
		if(typeof e.number == "undefined")
		{
			selectedSkuNumber = this.selectedSkuNumber;
		}else{
			selectedSkuNumber = e.number;
		}
		
		let selectedSkuId = '';
		if(typeof e.sku_id == "undefined")
		{
			selectedSkuId = this.selectedSkuId;
		}else{
			selectedSkuId = e.sku_id;
		}
		
        let that = this;
        let goodsInfo = this.goodsInfo;
        
        this.checkLogin(function(){
          //判断商品是否有开启SKU属性
          if(goodsInfo.have_sale_attr)
          {
            if(selectedSkuId>0){
              that.addToCart(goodsInfo.goods_id,selectedSkuId,selectedSkuNumber,'',function(res){
                if(res.err){
                  that.$toast(res.msg);
                }else{
                  that.$toast(res.msg);
                  that.refeshCartTotal();
                  that.showSkuPop = false
                }
              });
            }else{
				that.$toast("请选择要购买的规格");
              that.showSkuPop = true
            }
          } else {
            if(goodsInfo.sku_id=='0'){
              //如果是SKU类型商品,弹出商品选择框
              that.showSkuPop = true
            }else{
              //如果非SKU类型商品，直接加入到购物车
              that.addToCart(goodsInfo.goods_id,goodsInfo.sku_id,selectedSkuNumber,'',function(res){
                if(res.err){
                  that.$toast(res.msg);
                }else{
                  that.$toast(res.msg);
                  that.refeshCartTotal();
                  that.showSkuPop = false
                }
              });
            }
          }
        });
      },
      /**
       * 立即购买
       */
      quickToBuy:function(e){
        let that = this;
        let goodsInfo = this.goodsInfo;
        //判断商品是否有开启SKU属性
        if(goodsInfo.have_sale_attr) 
		{
		  //console.log(this.selectedSkuId);
          if(this.selectedSkuId>0)
		  {
            let skuid = this.selectedSkuId;
            let number = this.selectedSkuNumber;
            let cart = [{sku_id:skuid,number:number}];

            this.fetchQuickToBuy(cart)
          }else{
			this.$toast("请选择要购买的规格");
            this.showSkuPop = true
          }
        }
		else
		{
			console.log(e);
          if(e!='101'){
            //如果是SKU类型商品,弹出商品选择框
            that.showSkuPop = true
          }else{
            //如果非SKU类型商品，直接跳转购买
            let skuid = goodsInfo.sku_id;
            let number = this.selectedSkuNumber;
            let cart = [{sku_id:skuid,number:number}];
            this.fetchQuickToBuy(cart)
          }
        }

      },

      fetchQuickToBuy(cart){
        this.post(
          this.API.quickToBuy,
          {cart}
        ).then(response => {
          if(response.data.err=='1') {
            this.$toast(response.data.msg);
            return;
          }

          var storehouse_id = response.data.data;

          cart.forEach(function(i,k){
            cart[k]['storehouse_id'] = storehouse_id;
          })

          cart =  JSON.stringify(cart);
          this.$router.push({
            name: 'spjs',
            query: {
              cart,
              quick: 1,
            }
          })
        })
      },
      /**
       * 更新已选择的规格
       */
      updateSelectedSku:function(e){
        console.log(e);
        this.selectedSkuId = e.sku_id
        this.selectedSkuName = e.sku_name
        this.selectedSkuNumber=e.number
      },
      /**
       * 设置swiper 图片宽度
       */
      onSwiperLoad:function(e){
        const {height,width}=e.detail
        this.swiperImgWidth = 640*(width/height)
      },
      /**
       * 去分享
       */
      goShare:function(){
        this.showShare = true
      },
      /**
       * 取消分享
       */
      cancelShare:function(){
        this.showShare=false
      },
      /**
       * 选择分享的类型
       */
      selectShare:function(event){
        console.log(event.detail);
        this.cancelShare();
      },
      /**
       * 购物车页面
       */
      goCartPage(){
        this.$router.push({
          name: 'cart',
        })
      },
	  closeSkuPop(val){
	  	this.showSkuPop=val
	  	this.skuPopTitle="选择商品规格"
	  }
	  
    }
  };
</script>

<style>
	.details .content p img {
		width: 100% !important;
		display: inherit;
	}
</style>
<style lang="scss" scoped>
  /*banner区*/
  .banner-box {
    /*height: 486px;*/
  }
  .banner-box .goodsSwiper swiper {
      height: 600rpx;
  }
  .banner-box .goodsSwiper .swiper-item {
      text-align: center;
      background-color:#efefef;
  }
  .banner-box .goodsSwiper .swiper-item img {
      height: 600px;
	  width: 100%;
  }
  .banner-box .desc-box {
    padding: 30px;
  }
  
  .banner-box .title-box {
    justify-content: space-between;
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }
  .banner-box .title-box .title1 {
  }
  .banner-box .title-box .share {
    font-size: 24px;
    font-weight: normal;
  }
  .banner-box .title-box .share .iconfont {
    margin-right: 5px;
  }
  .banner-box .price-box {
    justify-content: space-between;
	margin-top: 10px;
    margin-bottom: 20px;
  }
  .banner-box .price-box .yx {
    font-size: 28px;
    font-weight: bold;
    color: #777777;
  }
  .banner-box .price-box .price {
    margin-right: 30px;
    font-size: 56px;
    font-weight: bold;
    color: #F84F41;
    text-indent: -4px;
  }
  .banner-box .price-box .huiyuan {
    text-align: center;
    font-size: 24px;
  }
  .banner-box .price-box .huiyuan .hy1 {
    display: inline-block;
    width: 92px;
    height: 28px;
    line-height: 28px;
    background-image: linear-gradient(106deg, #464E51 0%, #454D51 100%);
    border-radius: 4px 0 0 4px;
    color: #E4DCAD;
  }
  .banner-box .price-box .huiyuan .hy2 {
    display: inline-block;
    width: 92px;
    height: 28px;
    line-height: 28px;
    background-image: linear-gradient(48deg, #FCE7BD 0%, #FFF6D4 100%);
    border-radius: 0 4px 4px 0;
    color: #4C462C;
  }
  
  .gap {
    height: 20px;
    background-color: #F5F5F5;
  }
  
  .kucun-box {
    padding: 30px;
    font-size: 28px;
    color: #191919;
  }
  .kucun-box .kc {
    margin-bottom: 30px;
  }
  .kucun-box .left {
    width: 124px;
  }
  .kucun-box .mj {
    margin-right: 10px;
    width: 120px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #F84F41;
    border-radius: 6px;
    font-size: 20px;
    color: #F84F41;
  }
  
  .details {
    padding: 30px;
    padding-bottom: 120px;
  }
  .details .title {
    margin-bottom: 50px;
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }
  .details .content p img {
	  width:100% !important;
  }
  
  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 0 30px;
    height: 120px;
    background-color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .bottom .item {
    justify-content: center;
    flex-direction: column;
  }
  .bottom .item .iconfont {
    display: block;
    font-size: 40px;
  }
  
  .bottom .action-box {
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 92px;
  }
  .bottom .action-box .left {
    width: 282px;
    height: 92px;
    border-radius: 46px 0 0 46px;
    background-image: linear-gradient(0deg, #1D262E 0%, #1D262E 100%);
  }
  .bottom .action-box .right {
    width: 282px;
    height: 92px;
    border-radius: 0 46px 46px 0;
    background-color: #FCC601;
  }
  .otherInfo .title {
      font-size: 32px;
      font-weight: bold;
      color: #191919;
      padding:30px 10px; 
  }
  .badge {
	  width: 30px;
	  height: 30px;
	  color:#FFFFFF;
	  margin-left: 20px;
	  margin-top: -15px;
	  line-height: 28px;
	  position: absolute;
	  font-style: normal;
      text-align: center;
      border-radius: 15px;
	  vertical-align: middle;
	  background-color:#FF0000;
  }
</style>
